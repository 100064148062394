<template>
  <div class="d-flex flex-column">
    <h1>{{ pageTitle }}</h1>

    <div class="accordion" role="tablist">
      <SmartVmsSetting></SmartVmsSetting>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SmartVmsSetting from "@/components/Settings/SmartVmsSetting";

export default {
  components: {
    SmartVmsSetting
  },
  data() {
    return {
      pageTitle: "SmartVMS Settings"
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser"
    })
  },
  methods: {}
};
</script>

<style lang="scss"></style>

<template>
  <div>
    <b-overlay :show="show1" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.vms_preference class="d-flex justify-content-between">
            <div class="h6">
              <strong> VMS Preference Setting</strong>
            </div>
            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="vms_preference" role="tabpanel">
          <b-card-body>
            <b-table-simple div responsive striped>
              <b-tbody>
                <b-tr>
                  <b-td>Tolerance for Start Time</b-td>
                  <b-td>
                    <b-input-group>
                      <b-form-input
                        type="number"
                        v-model="$v.vmsPreferenceForm.tolerance_time_in.$model"
                        aria-describedby="vmsPreferenceForm.tolerance_time_in_feedback"
                        :state="validatevmsPreferenceForm('tolerance_time_in')"
                      ></b-form-input>
                      <b-input-group-text
                        ><strong>Minutes</strong></b-input-group-text
                      >
                      <b-form-invalid-feedback
                        class="invalid-feedback"
                        id="vmsPreferenceForm.tolerance_time_in_feedback"
                      >
                        Tolerance time should be maximum 30 minutes.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Tolerance for End Time</b-td>
                  <b-td>
                    <b-input-group>
                      <b-form-input
                        type="number"
                        v-model="$v.vmsPreferenceForm.tolerance_time_out.$model"
                        :state="validatevmsPreferenceForm('tolerance_time_out')"
                        aria-describedby="vmsPreferenceForm.tolerance_time_out_feedback"
                      ></b-form-input>
                      <b-input-group-text
                        ><strong>Minutes</strong></b-input-group-text
                      >
                      <b-form-invalid-feedback
                        class="invalid-feedback"
                        id="vmsPreferenceForm.tolerance_time_out_feedback"
                      >
                        Tolerance time should be maximum 30 minutes.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Default Visitor Type</b-td>
                  <b-td>
                    <b-form-select
                      v-model="
                        vmsPreferenceForm.additional_metadata
                          .vms_preferences_default_visitor_type
                      "
                      :options="visitorTypeOptions"
                      placeholder="Choose default visitor type"
                      value-field="name"
                      text-field="name"
                    ></b-form-select>
                  </b-td>
                </b-tr>
                <!-- <b-tr>
                  <b-td>Visit Duration by Visitor Types</b-td>
                  <b-td>
                    <div class="d-flex justify-content-start">
                      <div>
                        <label>Visitor Type</label>
                        <b-form-select
                          v-model="
                            vmsPreferenceForm.additional_metadata
                              .vms_preferences_default_visitor_type
                          "
                          :options="visitorTypeOptions"
                          @change="handleChange"
                          placeholder="Choose default visitor type"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </div>
                      <div>
                        <label>Duration</label>
                        <b-form-input
                          type="number"
                          v-model="
                            vmsPreferenceForm.additional_metadata
                              .vms_preferences_duration_by_visitor_types
                          "
                          placeholder="Duration hours"
                        ></b-form-input>
                      </div>
                    </div>
                  </b-td>
                </b-tr> -->
                <!-- <b-tr>
                  <b-td>Credential issuance</b-td>
                  <b-td>
                    <div>
                      <b-form-checkbox
                        v-model="
                          vmsPreferenceForm.additional_metadata
                            .vms_preferences_credential_physical_card
                        "
                        @change="
                          onChangeCredentialIssuance('physical_card', $event)
                        "
                        >Physical Card
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="
                          vmsPreferenceForm.additional_metadata
                            .vms_preferences_credential_static_qr
                        "
                        :disabled="
                          vmsPreferenceForm.additional_metadata
                            .vms_preferences_credential_dynamic_qr
                        "
                        @change="
                          onChangeCredentialIssuance('static_qr', $event)
                        "
                        >Static QR
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="
                          vmsPreferenceForm.additional_metadata
                            .vms_preferences_credential_dynamic_qr
                        "
                        @change="
                          onChangeCredentialIssuance('dynamic_qr', $event)
                        "
                        :disabled="
                          vmsPreferenceForm.additional_metadata
                            .vms_preferences_credential_static_qr
                        "
                        >Dynamic QR
                      </b-form-checkbox>

                      <b-modal
                        id="dynamic-qr-form-modal"
                        :no-close-on-backdrop="true"
                        @ok="handleOk"
                        @close="handleClose"
                        @cancel="handleCancel"
                      >
                        <div class="d-flex flex-row justify-content-start">
                          Dynamic QR will automatically refresh every
                          {{ vmsSettingForm.dynamic_qr_validity }} seconds. If
                          you want to change the refresh timer, please update in
                          the box below. Otherwise just click ok
                        </div>

                        <div class="d-flex flex-row justify-content-start">
                          <b-input-group class="w-50">
                            <b-form-input
                              size="sm"
                              type="number"
                              v-model="
                                $v.vmsSettingForm.dynamic_qr_validity.$model
                              "
                              :state="
                                validatevmsSettingForm('dynamic_qr_validity')
                              "
                              aria-describedby="vmsSettingForm.dynamic_qr_validity_feedback"
                            ></b-form-input>
                            <b-input-text class="mt-3 ml-2"
                              >seconds</b-input-text
                            >
                            <b-form-invalid-feedback
                              class="invalid-feedback_"
                              id="vmsSettingForm.dynamic_qr_validity_feedback"
                            >
                              You can only input values between 10 to 60
                              seconds.
                            </b-form-invalid-feedback>
                          </b-input-group>
                        </div>
                      </b-modal>

                      <b-form-checkbox
                        v-model="
                          vmsPreferenceForm.additional_metadata
                            .vms_preferences_credential_face_recognition
                        "
                        @change="
                          onChangeCredentialIssuance('face_recognition', $event)
                        "
                        >Face Recognition
                      </b-form-checkbox>
                    </div>
                  </b-td>
                </b-tr> -->
                <b-tr>
                  <b-td>Access Level</b-td>
                  <b-td
                    ><multiselect
                      placeholder="Choose access level"
                      v-model="
                        vmsPreferenceForm.additional_metadata
                          .vms_preferences_access_level
                      "
                      label="access_level"
                      track-by="access_level"
                      :options="options_vms_preferences_access_level"
                    ></multiselect
                  ></b-td>
                </b-tr>
                <b-tr>
                  <b-td>Company Access Group</b-td>
                  <b-td>
                    <multiselect
                      placeholder="Choose access group"
                      v-model="
                        vmsPreferenceForm.additional_metadata
                          .vms_preferences_access_groups
                      "
                      label="access_group"
                      track-by="access_group"
                      :options="options_vms_preferences_access_groups"
                      :multiple="true"
                    ></multiselect
                  ></b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <b-button variant="primary" @click="handleUpdate('show1')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="show2" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.vms_process_setting
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> VMS Process Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="vms_process_setting" role="tabpanel">
          <b-card-body>
            <strong>Registration</strong>

            <div>
              <b-form-checkbox
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_registration_host_search
                "
                disabled
              >
                <strong>Host search</strong>
                <br />
                <div>
                  This is a mandatory field to identify host for each visit.
                </div>
              </b-form-checkbox>
              <b-form-checkbox
                class="mt-1"
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_registration_visitor_registration
                "
                disabled
                ><strong>Visitor registration</strong>
                <br />
                <div>
                  This is a mandatory field to identify visitor identity for
                  each visit.
                </div>
              </b-form-checkbox>
              <b-form-checkbox
                class="mt-1"
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_registration_visit_details
                "
                disabled
                ><strong>Visit details</strong>
                <br />
                <div>
                  This is a mandatory field to identify the time, date, location
                  and reason that a visit takes place.
                </div>
              </b-form-checkbox>
              <b-form-checkbox
                class="mt-1"
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_enable_biometric_scanner
                "
              >
                <strong
                  >Enable biometric scanner integration for adding visitor
                </strong>
                <span
                  v-b-tooltip.hover
                  title="Select this if biometric is setup."
                  ><i class="fas fa-info-circle"></i
                ></span>
              </b-form-checkbox>
              <b-form-checkbox
                class="mt-1"
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_registration_visitor_profile_picture_required
                "
                ><strong>Visitor profile required</strong>
                <br />
                <div>
                  Optional field: Once this function is enabled, new visitor
                  will be asked to take profile picture before proceed to
                  visitor registration.
                </div>
              </b-form-checkbox>
              <b-form-checkbox
                class="mt-1"
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_registration_declaration_form
                "
                @change="handleCustomForm"
                ><strong>Custom form</strong>
                <br />
                <div>
                  Optional field: Once this function is enabled, created form
                  will be prompted after visitor keyin personal information.
                  Visit will be invalid if visitor has not completed the form.
                </div>
              </b-form-checkbox>
            </div>
            <div
              class="px-3 py-2"
              v-if="
                vmsPreferenceForm.additional_metadata
                  .vms_registration_declaration_form
              "
            >
              <div class="form-container">
                <div id="form-editor" />
              </div>
            </div>
          </b-card-body>

          <b-card-body>
            <strong>Approval</strong>
            <b-form-checkbox
              disabled
              v-model="
                vmsPreferenceForm.additional_metadata.vms_approval_required
              "
              ><strong>Visit approval is required</strong>
              <br />
              <div>
                Optional field: Once this function is enabled, approval from
                Host or Operator is required for the visit created. Without
                approval, no access credential to be assigned for the visit.
              </div>
            </b-form-checkbox>
            <b-form-checkbox
              @change="onChange"
              v-model="
                vmsPreferenceForm.additional_metadata
                  .vms_host_invite_check_in_auto
              "
              ><strong>Host invite contactless check-in</strong>
              <br />
              <div>
                This is applicable for all credential issuance types except
                Physical Card.
              </div>
            </b-form-checkbox>
          </b-card-body>

          <b-card-body>
            <strong>Overstayed Visitor</strong>
            <b-form-checkbox
              v-model="vmsSettingForm.vms_overstayed_auto_checkout"
              ><strong>Enable/Disable Auto Checkout (Daily 12am)</strong>
            </b-form-checkbox>
            <b-button variant="primary" @click="confirmCheckOut()"
              >One-time Checkout All Visitor
            </b-button>
          </b-card-body>
          <!-- <b-card-body>
            <strong>Check In</strong>
            <b-form-checkbox
              v-model="
                vmsPreferenceForm.additional_metadata
                  .vms_check_in_as_soon_as_credential_is_issued
              "
              ><strong>Auto check-in as soon as credential is issued</strong>
              <br />
              <div>
                Optional field: Once this function is enabled, the visit will be
                marked as checked-in as soon as a credential is issued to the
                visit.
              </div>
            </b-form-checkbox>
          </b-card-body> -->

          <!-- <b-card-body>
            <strong>Check Out</strong>
            <b-form-checkbox
              v-model="vmsPreferenceForm.additional_metadata.vms_check_out_auto"
            >
              <div class="d-flex justify-content-start align-items-center">
                <div class="mr-2">Auto check-out if visit hits</div>
                <b-form-input
                  class="w-5"
                  size="sm"
                  type="number"
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_check_out_auto_hours
                  "
                ></b-form-input>
                <div class="ml-2">hours</div>
              </div>
              <br />
              <div>
                Optional field: Once this function is enabled, any checked in
                visit which is not checked out more than indicated hours, the
                visit will be marked as checked out.
              </div>
            </b-form-checkbox>
          </b-card-body> -->

          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show2')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="show3" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.credential_issuance
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> Credential Issuance Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="credential_issuance" role="tabpanel">
          <b-card-body>
            <strong>Operator</strong>
            <div>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('physical_card_operator', $event)
                "
                :disabled="
                  vmsPreferenceForm.additional_metadata
                    .vms_host_invite_check_in_auto
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_physical_card
                "
                >Physical Card
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_lift_access_physical_card
                  "
                  :disabled="
                    vmsPreferenceForm.additional_metadata
                      .vms_host_invite_check_in_auto ||
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_physical_card
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('static_qr_operator', $event)
                "
                :disabled="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_dynamic_qr
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_static_qr
                "
                >Static QR
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_lift_access_static_qr
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_static_qr
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('dynamic_qr_operator', $event)
                "
                :disabled="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_static_qr
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_dynamic_qr
                "
                >Dynamic QR
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_lift_access_dynamic_qr
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_dynamic_qr
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance(
                    'face_recognition_operator',
                    $event
                  )
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_face_recognition
                "
                >Face Recognition
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_lift_access_face_recognition
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_face_recognition
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance(
                    'license_plate_recognition_operator',
                    $event
                  )
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_license_plate_recognition
                "
                >License Plate Recognition
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_lift_access_license_plate_recognition
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_license_plate_recognition
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('fingerprint_operator', $event)
                "
                :disabled="
                  vmsPreferenceForm.additional_metadata
                    .vms_host_invite_check_in_auto
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_operator_fingerprint
                "
                >Fingerprint
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_lift_access_fingerprint
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_operator_fingerprint
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
            </div>
          </b-card-body>

          <b-card-body>
            <strong>Kiosk</strong>
            <div>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('physical_card_kiosk', $event)
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_physical_card
                "
                >Physical Card
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_lift_access_physical_card
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_physical_card
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="onChangeCredentialIssuance('static_qr_kiosk', $event)"
                :disabled="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_dynamic_qr
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_static_qr
                "
                >Static QR
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_lift_access_static_qr
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_static_qr
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="onChangeCredentialIssuance('dynamic_qr_kiosk', $event)"
                :disabled="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_static_qr
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_dynamic_qr
                "
                >Dynamic QR
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_lift_access_dynamic_qr
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_dynamic_qr
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('face_recognition_kiosk', $event)
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_face_recognition
                "
                >Face Recognition
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_lift_access_face_recognition
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_face_recognition
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance(
                    'license_plate_recognition_kiosk',
                    $event
                  )
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_license_plate_recognition
                "
                >License Plate Recognition
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_lift_access_license_plate_recognition
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_license_plate_recognition
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
              <b-form-checkbox
                @change="
                  onChangeCredentialIssuance('fingerprint_kiosk', $event)
                "
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_credential_kiosk_fingerprint
                "
                >Fingerprint
                <b-form-checkbox
                  v-model="
                    vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_lift_access_fingerprint
                  "
                  :disabled="
                    !vmsPreferenceForm.additional_metadata
                      .vms_credential_kiosk_fingerprint
                  "
                  >Include Lift Access
                </b-form-checkbox>
              </b-form-checkbox>
            </div>
          </b-card-body>

          <b-card-body>
            <strong>Public URL</strong>
            <p>
              Please do take note that all visitors who has registered via host
              invitation or public URL, he/she must go to operator or kiosk for
              check-in and credential issuance.
            </p>
          </b-card-body>

          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show3')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
        <b-modal
          id="dynamic-qr-form-modal"
          :no-close-on-backdrop="true"
          @ok="handleOk"
          @close="handleClose"
          @cancel="handleCancel"
        >
          <div class="d-flex flex-row justify-content-start">
            Dynamic QR will automatically refresh every
            {{ vmsSettingForm.dynamic_qr_validity }} seconds. If you want to
            change the refresh timer, please update in the box below. Otherwise
            just click ok
          </div>

          <div class="d-flex flex-row justify-content-start">
            <b-input-group class="w-50">
              <b-form-input
                size="sm"
                type="number"
                v-model="$v.vmsSettingForm.dynamic_qr_validity.$model"
                :state="validatevmsSettingForm('dynamic_qr_validity')"
                aria-describedby="vmsSettingForm.dynamic_qr_validity_feedback"
              ></b-form-input>
              <span class="mt-3 ml-2">seconds</span>
              <b-form-invalid-feedback
                class="invalid-feedback_"
                id="vmsSettingForm.dynamic_qr_validity_feedback"
              >
                You can only input values between 10 to 60 seconds.
              </b-form-invalid-feedback>
            </b-input-group>
          </div>
        </b-modal>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <!--    <b-card no-body class="mt-3">-->
    <!--      <b-card-header header-tag="header" role="tab">-->
    <!--        <div-->
    <!--          v-b-toggle.notification_setting-->
    <!--          class="d-flex justify-content-between"-->
    <!--        >-->
    <!--          <div class="h6">-->
    <!--            <strong> Notification Setting </strong>-->
    <!--          </div>-->

    <!--          <span class="when-opened">-->
    <!--            <i class="fa fa-chevron-up" aria-hidden="true"></i-->
    <!--          ></span>-->
    <!--          <span class="when-closed">-->
    <!--            <i class="fa fa-chevron-down" aria-hidden="true"></i-->
    <!--          ></span>-->
    <!--        </div>-->
    <!--      </b-card-header>-->
    <!--      <b-collapse id="notification_setting" role="tabpanel">-->
    <!--        <b-card-body>-->
    <!--          <div class="d-flex mb-2">-->
    <!--            <b-form-checkbox switch></b-form-checkbox>-->
    <!--            Email-->
    <!--          </div>-->

    <!--          <b-table-simple div responsive striped>-->
    <!--            <b-thead>-->
    <!--              <b-tr>-->
    <!--                <b-th></b-th>-->
    <!--                <b-th>Type of email</b-th>-->
    <!--                <b-th>Details</b-th>-->
    <!--              </b-tr>-->
    <!--            </b-thead>-->

    <!--            <b-tbody>-->
    <!--              <b-tr>-->
    <!--                <b-td>-->
    <!--                  <b-form-checkbox switch></b-form-checkbox>-->
    <!--                </b-td>-->
    <!--                <b-td>Visit registration is completed</b-td>-->
    <!--                <b-td-->
    <!--                  >As soon as a visit is created with visitor information, visit-->
    <!--                  appointment time and host information, a notification will be-->
    <!--                  sent to visitor's email address.-->
    <!--                </b-td>-->
    <!--              </b-tr>-->

    <!--              <b-tr>-->
    <!--                <b-td>-->
    <!--                  <b-form-checkbox switch></b-form-checkbox>-->
    <!--                </b-td>-->
    <!--                <b-td>Visit approval is completed</b-td>-->
    <!--                <b-td-->
    <!--                  >If a visit approval is required, an email notification will-->
    <!--                  be sent to visitor as soon as the visit is approved.-->
    <!--                </b-td>-->
    <!--              </b-tr>-->

    <!--              <b-tr>-->
    <!--                <b-td>-->
    <!--                  <b-form-checkbox switch></b-form-checkbox>-->
    <!--                </b-td>-->
    <!--                <b-td>Visit access is completed</b-td>-->
    <!--                <b-td-->
    <!--                  >An email notification will be sent to visitor upon the visit-->
    <!--                  is assigned with a credential. Email shall consists the type-->
    <!--                  of credential issued, and instruction on how to use the-->
    <!--                  credential.-->
    <!--                </b-td>-->
    <!--              </b-tr>-->

    <!--              <b-tr>-->
    <!--                <b-td>-->
    <!--                  <b-form-checkbox switch></b-form-checkbox>-->
    <!--                </b-td>-->
    <!--                <b-td>Visitor arrival notification</b-td>-->
    <!--                <b-td-->
    <!--                  >An email notification will be sent to Host's email address-->
    <!--                  upon visitor arrival. Please take note that configuration of-->
    <!--                  entrance door needs to be done in workflow engine.-->
    <!--                </b-td>-->
    <!--              </b-tr>-->
    <!--            </b-tbody>-->
    <!--          </b-table-simple>-->

    <!--          <b-button variant="primary">Update</b-button>-->
    <!--        </b-card-body>-->
    <!--      </b-collapse>-->
    <!--    </b-card>-->

    <b-overlay :show="show4" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.kiosk_settings class="d-flex justify-content-between">
            <div class="h6">
              <strong> Kiosk Settings </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="kiosk_settings" role="tabpanel">
          <b-card-body>
            <strong>Welcome Page Setting</strong>
            <b-form-group label="Welcome message">
              <b-form-input
                v-model="
                  vmsPreferenceForm.additional_metadata
                    .vms_kiosk_welcome_message
                "
              ></b-form-input>
            </b-form-group>

            <div>Branding Image</div>
            <div>
              Recommended ratio is 10:1 (e.g. 1000px x 100px). File size limit
              is 1 MB.
            </div>

            <b-badge
              v-if="
                vmsPreferenceForm.additional_metadata
                  .vms_kiosk_branding_image != null
              "
            >
              <div class="public-url-company-banner">
                <img
                  :src="
                    showImage(
                      vmsPreferenceForm.additional_metadata
                        .vms_kiosk_branding_image
                    )
                  "
                  fluid
                />
              </div>
            </b-badge>
            <b-badge v-else>
              <div>-</div>
            </b-badge>

            <b-form-file
              id="vms_kiosk_company_banner"
              accept="image/*"
              @change="onFileChange(null, $event, 'kiosk')"
              style="display: none"
            ></b-form-file>
            <b-button
              class="ml-2 mr-1"
              size="md"
              v-b-tooltip.hover
              title="Upload Photo"
              @click="uploadPhoto('kiosk')"
            >
              <i class="fas fa-file-upload"></i> Select Image
            </b-button>
          </b-card-body>

          <b-card-body>
            <strong>Accept terms and condition setting</strong>
            <div>
              Once this is enabled, the visitor will need to agree the terms and
              conditions before he/she can complete to visit registration.
            </div>

            <div class="d-flex mb-2">
              <b-form-checkbox
                switch
                v-model="
                  vmsPreferenceForm.additional_metadata.vms_kiosk_tnc_required
                "
                >Accept terms and condition
              </b-form-checkbox>
            </div>

            <b-form-group label="Terms and condition URL">
              <b-form-input
                placeholder="url"
                v-model="vmsPreferenceForm.tnc_metadata.kiosk.tnc_url"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Privacy policy URL">
              <b-form-input
                placeholder="url"
                v-model="vmsPreferenceForm.tnc_metadata.kiosk.policy_url"
              ></b-form-input>
            </b-form-group>
          </b-card-body>

          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show4')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="show5" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.public_url_settings
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> Public URL Settings </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="public_url_settings" role="tabpanel">
          <b-card-body>
            <p>Public URL</p>

            <b-input-group>
              <b-form-input
                v-model="vmsSettingForm.public_hostname"
                required
              ></b-form-input>
              <template #prepend>
                <b-form-select v-model="vmsSettingForm.public_protocol">
                  <b-form-select-option value="https://"
                    >https://
                  </b-form-select-option>
                  <b-form-select-option value="http://"
                    >http://
                  </b-form-select-option>
                </b-form-select>
              </template>
              <template #append>
                <b-input-group-text
                  ><strong>/public/registration</strong></b-input-group-text
                >
              </template>
            </b-input-group>
          </b-card-body>

          <b-card-body>
            <p>QR Generator</p>
            <b-button
              @click="handleQRGeneratorPreview"
              v-b-tooltip.hover
              title="Click this button if you want preview QR Generator"
              >QR Generator
            </b-button>

            <b-modal
              title="QR Generator"
              class="bg-white text-black p-3"
              ref="previewQRGenerator"
              :hide-footer="true"
              centered
              :scrollable="true"
            >
              <div class="m-1 p-1" ref="previewPdfHtml">
                <div class="text-center">
                  <div class="qrGeneratorFont1">Scan Me</div>
                  <qrcode-vue
                    :value="`${vmsSettingForm.public_protocol}${vmsSettingForm.public_hostname}/public/registration`"
                    :size="300"
                    level="H"
                    class="d-flex justify-content-center"
                  />

                  <div class="qrGeneratorFont2 mt-4">
                    Kindly Scan the QR Code<span class="mx-2"
                      >or use the URL below</span
                    >
                    <span>to Pre-Registration</span>
                  </div>
                  <div class="qr-font mt-2">
                    {{
                      `${vmsSettingForm.public_protocol}${vmsSettingForm.public_hostname}/public/registration`
                    }}
                  </div>
                  <div class="qrGeneratorFont2 mt-5">Thank You</div>
                </div>
              </div>

              <div class="mt-4 d-flex justify-content-end">
                <b-button variant="primary" class="mr-1" @click="printPdf"
                  >Print
                </b-button>
                <b-button variant="secondary" @click="downloadPdf"
                  >Download
                </b-button>
              </div>
            </b-modal>
          </b-card-body>

          <b-card-body>
            <strong>Welcome Page Setting</strong>
            <b-form-group label="Welcome message">
              <b-form-input
                v-model="
                  vmsPreferenceForm.additional_metadata.public
                    .vms_public_welcome_message
                "
              ></b-form-input>
            </b-form-group>

            <div>Branding Image</div>
            <div>
              Recommended ratio is 10:1 (e.g. 1000px x 100px). File size limit
              is 1 MB.
            </div>
            <b-badge
              v-if="
                vmsPreferenceForm.additional_metadata.public
                  .vms_public_branding_image != null
              "
            >
              <div class="public-url-company-banner">
                <img
                  :src="
                    showImage(
                      vmsPreferenceForm.additional_metadata.public
                        .vms_public_branding_image
                    )
                  "
                  fluid
                />
              </div>
            </b-badge>
            <b-badge v-else>
              <div>-</div>
            </b-badge>

            <b-form-file
              id="vms_public_url_company_banner"
              accept="image/*"
              @change="onFileChange(null, $event, 'public')"
              style="display: none"
            ></b-form-file>
            <b-button
              class="ml-2 mr-1"
              size="md"
              v-b-tooltip.hover
              title="Upload Photo"
              @click="uploadPhoto('public')"
            >
              <i class="fas fa-file-upload"></i> Select Image
            </b-button>
          </b-card-body>

          <b-card-body>
            <strong>Accept terms and condition setting</strong>
            <div>
              Once this is enabled, the visitor will need to agree the terms and
              conditions before he/she can complete to visit registration.
            </div>

            <div class="d-flex mb-2">
              <b-form-checkbox
                switch
                v-model="
                  vmsPreferenceForm.additional_metadata.vms_public_tnc_required
                "
                >Accept terms and condition
              </b-form-checkbox>
            </div>

            <b-form-group label="Terms and condition URL">
              <b-form-input
                placeholder="url"
                v-model="vmsPreferenceForm.tnc_metadata.public.tnc_url"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Privacy policy URL">
              <b-form-input
                placeholder="url"
                v-model="vmsPreferenceForm.tnc_metadata.public.policy_url"
              ></b-form-input>
            </b-form-group>
          </b-card-body>

          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show5')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="show6" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.report_setting class="d-flex justify-content-between">
            <div class="h6">
              <strong> Report Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="report_setting" role="tabpanel">
          <b-card-body>
            <div class="d-flex">
              <b-form-checkbox
                switch
                v-model="vmsSettingForm.generate_scheduled_report"
                ><strong>Scheduled daily report</strong></b-form-checkbox
              >
            </div>
            <div>
              To generate reports on a daily basis, click daily report radio
              button and specify from date, time, report to be scheduled and the
              email address of the person to whom the report has to be sent.
            </div>
          </b-card-body>
          <b-card-body>
            <div><strong>Daily Report Settings</strong></div>

            <div>Run daily</div>
            <b-form-checkbox-group
              v-model="vmsSettingForm.report_generation_days_array"
            >
              <b-form-checkbox :value="0">Sunday</b-form-checkbox>
              <b-form-checkbox :value="1">Monday</b-form-checkbox>
              <b-form-checkbox :value="2">Tuesday</b-form-checkbox>
              <b-form-checkbox :value="3">Wednesday</b-form-checkbox>
              <b-form-checkbox :value="4">Thursday</b-form-checkbox>
              <b-form-checkbox :value="5">Friday</b-form-checkbox>
              <b-form-checkbox :value="6">Saturday</b-form-checkbox>
            </b-form-checkbox-group>

            <b-form-group label="Generation Time" class="mt-2">
              <b-time
                v-model="vmsSettingForm.report_generation_time"
                :hourCycle="this.hourCycle"
                :hour12="false"
                locale="en"
                show-seconds
              ></b-time>
            </b-form-group>

            <b-form-group label="Report receipients" class="mt-2">
              <b-form-tags
                placeholder=" Enter email..."
                v-model="vmsSettingForm.recipient_list"
                :tag-validator="tagValidator"
                separator=" "
                ref="input"
              ></b-form-tags>
            </b-form-group>
          </b-card-body>
          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show6')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="show7" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.privacy_settings
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> Privacy Settings </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="privacy_settings" role="tabpanel">
          <b-card-body>
            <strong>Data retention setting</strong>
            <div class="d-flex justify-content-start align-items-center mt-2">
              <div class="mr-2">Keep Visit Event for</div>
              <b-form-input
                class="w-5"
                size="sm"
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-model="vmsSettingForm.visit_housekeeping_days"
              ></b-form-input>
              <div class="ml-2">days</div>
            </div>

            <div class="d-flex justify-content-start align-items-center mt-2">
              <div class="mr-2">Keep Visitor Profile for</div>
              <b-form-input
                class="w-5"
                size="sm"
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-model="vmsSettingForm.visitor_housekeeping_days"
              ></b-form-input>
              <div class="ml-2">days</div>
            </div>

            <div class="d-flex justify-content-start align-items-center mt-2">
              <div class="mr-2">Keep Daily Report for</div>
              <b-form-input
                class="w-5"
                size="sm"
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-model="vmsSettingForm.report_housekeeping_days"
              ></b-form-input>
              <div class="ml-2">days</div>
            </div>
          </b-card-body>

          <b-card-body>
            <strong>PDPA Setting</strong>

            <b-form-radio-group
              v-model="vmsPreferenceForm.additional_metadata.vms_pdpa_setting"
            >
              <div class="d-flex flex-column justify-content-start">
                <b-form-radio value="save_full"
                  >Save FULL identity card/passport number into
                  database</b-form-radio
                >
                <b-form-radio value="save_partial"
                  >Save PARTIAL identity card/passport number into database
                  <span
                    v-b-tooltip.hover
                    title="Example : 
                  Last 4 digits hp no (h/p: 01755671688) & Partial NRIC/Passport (********7788).
                  Preview of visitor identifier saved in database : 16887788"
                    ><i class="fas fa-info-circle"></i></span
                ></b-form-radio>
              </div>
            </b-form-radio-group>
          </b-card-body>

          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show7')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="show8" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.visitor_duration_option_settings
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> Visitor Duration Option Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="visitor_duration_option_settings" role="tabpanel">
          <b-card-body>
            <strong
              >Visitor Duration Option Setting (Public URL and Kiosk
              only)</strong
            >
            <!--            <b-row>-->
            <b-form-radio-group
              v-model="
                vmsPreferenceForm.additional_metadata.public
                  .vms_public_default_duration
              "
              class="d-flex flex-column"
            >
              <!--                <b-col>-->
              <b-form-radio value="duration_time_range" class="mb-2">
                <b-row no-gutters>
                  <b-col lg="6">
                    <b-form-group label="Visit Start Time">
                      <b-time
                        v-model="
                          vmsPreferenceForm.additional_metadata.public
                            .vms_public_visit_start_time
                        "
                        :hourCycle="this.hourCycle"
                        :hour12="false"
                        locale="en"
                        show-seconds
                      ></b-time>
                    </b-form-group>
                  </b-col>

                  <b-col lg="2">
                    <div class="text-center my-5">To</div>
                  </b-col>

                  <b-col lg="4">
                    <b-form-group label="Visit End Time">
                      <b-time
                        v-model="
                          vmsPreferenceForm.additional_metadata.public
                            .vms_public_visit_end_time
                        "
                        :hourCycle="this.hourCycle"
                        :hour12="false"
                        locale="en"
                        show-seconds
                      ></b-time>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-radio>
              <!--                </b-col>-->
              <!--                <b-col>-->
              <b-form-radio value="duration_hours" class="mb-2">
                <b-row class="visit-align" no-gutters>
                  <b-col sm="6" class="mt-1"
                    >Visit End Time is Visit Start Time add</b-col
                  >
                  <b-col sm="6" class="d-flex">
                    <b-form-select
                      v-model="
                        vmsPreferenceForm.additional_metadata.public
                          .vms_public_duration_hours
                      "
                      :options="options_vms_public_duration_hours"
                      class="duration-select"
                    ></b-form-select>
                    <div class="mt-2 ml-1">hours</div>
                  </b-col>
                </b-row>
              </b-form-radio>
              <!--                </b-col>-->
              <!--                <b-col>-->
              <b-form-radio value="duration_end_time" class="mb-2">
                <b-form-group label="Visit End Time">
                  <b-time
                    v-model="
                      vmsPreferenceForm.additional_metadata.public
                        .vms_public_visit_end_time_only
                    "
                    :hourCycle="this.hourCycle"
                    :hour12="false"
                    locale="en"
                    show-seconds
                  ></b-time>
                </b-form-group>
              </b-form-radio>
              <!--                </b-col>-->
              <!--                <b-col>-->
              <b-form-radio value="duration_days" class="mb-2"
                >Others:<br />
                <div class="d-flex">
                  <div class="mt-2 mr-2">Visit End After</div>
                  <div class="d-flex">
                    <b-input
                      v-model="
                        vmsPreferenceForm.additional_metadata.public
                          .vms_public_duration_days
                      "
                      type="number"
                      placeholder="Please insert days"
                    ></b-input>
                    <div class="mt-2 ml-2">days</div>
                  </div>
                </div>
              </b-form-radio>
              <!--                </b-col>-->
            </b-form-radio-group>
            <!--            </b-row>-->
            <div class="mt-3">
              *Notes: Allow visitor access/exit based on duration setting above.
            </div>
          </b-card-body>

          <b-card-body>
            <b-button variant="primary" @click="handleUpdate('show8')"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment";
import jQuery from "jquery";
import QrcodeVue from "qrcode.vue";
import formBuilder from "formBuilder";
import Multiselect from "vue-multiselect";

import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { maxValue, between, required } from "vuelidate/lib/validators";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

export default {
  mixins: [validationMixin],
  data: function () {
    return {
      firstLoad: false,
      hourCycle: "h23",
      visitor_type: "",
      sampleHtmlContent: "",

      duration_by_visitor_types: 0,

      options_vms_preferences_access_level: [],
      options_vms_preferences_access_groups: [],
      options_vms_public_duration_hours: [1, 2, 3, 4, 5, 6, 7, 8],

      oldVal: {},
      vmsPreferenceForm: {
        forms: {},
        forms_compulsory_fields: {},

        tolerance_time_in: 0,
        tolerance_time_out: 0,
        additional_metadata: {
          vms_preferences_access_level: [],
          vms_preferences_access_groups: [],
          vms_preferences_default_visitor_type: "",
          /* vms_preferences_credential_physical_card: false,
          vms_preferences_credential_static_qr: false,
          vms_preferences_credential_dynamic_qr: false,
          vms_preferences_credential_face_recognition: false, */
          vms_registration_host_search: false,
          vms_registration_visitor_registration: false,
          vms_registration_visit_details: false,
          vms_registration_visitor_profile_picture_required: false,
          vms_registration_declaration_form: false,
          vms_enable_biometric_scanner: false,
          vms_approval_required: false,
          vms_check_in_as_soon_as_credential_is_issued: false,
          vms_check_out_auto: false,
          vms_check_out_auto_hours: 8,
          //operator
          vms_credential_operator_lift_access_physical_card: false,
          vms_credential_operator_lift_access_static_qr: false,
          vms_credential_operator_lift_access_dynamic_qr: false,
          vms_credential_operator_lift_access_face_recognition: false,
          vms_credential_operator_physical_card: false,
          vms_credential_operator_static_qr: false,
          vms_credential_operator_dynamic_qr: false,
          vms_credential_operator_face_recognition: false,
          //kiosk
          vms_credential_kiosk_lift_access_physical_card: false,
          vms_credential_kiosk_lift_access_static_qr: false,
          vms_credential_kiosk_lift_access_dynamic_qr: false,
          vms_credential_kiosk_lift_access_face_recognition: false,
          vms_credential_kiosk_physical_card: false,
          vms_credential_kiosk_static_qr: false,
          vms_credential_kiosk_dynamic_qr: false,
          vms_credential_kiosk_face_recognition: false,
          //new addition
          //operator
          vms_credential_operator_license_plate_recognition: false,
          vms_credential_operator_fingerprint: false,
          vms_credential_operator_lift_access_license_plate_recognition: false,
          vms_credential_operator_lift_access_fingerprint: false,
          //kiosk
          vms_credential_kiosk_license_plate_recognition: false,
          vms_credential_kiosk_fingerprint: false,
          vms_credential_kiosk_lift_access_license_plate_recognition: false,
          vms_credential_kiosk_lift_access_fingerprint: false,
          vms_kiosk_welcome_message:
            "Welcome to Entrypass Corporation Sdn. Bhd.",
          vms_kiosk_branding_image: "",
          national_id_type: {
            HKG: ["HKID", "Passport"],
            IDN: ["KTP", "SIM", "Passport"],
            MYS: ["MyKAD", "Passport"],
            SGP: ["NRIC", "Passport"]
          },
          public: {
            vms_public_welcome_message:
              "Welcome to Entrypass Corporation Sdn. Bhd.",
            vms_public_branding_image: "",
            vms_public_default_duration: "duration_time_range",
            vms_public_visit_start_time: "10:00:00",
            vms_public_visit_end_time: "18:00:00",
            vms_public_duration_hours: 2,
            vms_public_visit_end_time_only: "18:00:00",
            vms_public_duration_days: 0,

            vms_pdpa_setting: "save_full",
            vms_preferences_default_visitor_type: "",
            vms_host_invite_check_in_auto: true,
            vms_credential_operator_lift_access_physical_card: false,
            vms_credential_operator_lift_access_static_qr: false,
            vms_credential_operator_lift_access_dynamic_qr: false,
            vms_credential_operator_lift_access_face_recognition: false,
            vms_credential_operator_physical_card: false,
            vms_credential_operator_static_qr: false,
            vms_credential_operator_dynamic_qr: false,
            vms_credential_operator_face_recognition: false,
            //new addition
            vms_credential_operator_lift_access_license_plate_recognition: false,
            vms_credential_operator_lift_access_fingerprint: false,
            vms_credential_operator_license_plate_recognition: false,
            vms_credential_operator_fingerprint: false,

            national_id_type: {
              HKG: ["HKID", "Passport"],
              IDN: ["KTP", "SIM", "Passport"],
              MYS: ["MyKAD", "Passport"],
              SGP: ["NRIC", "Passport"]
            },

            vms_preferences_access_level: [],
            vms_preferences_access_groups: []
          },
          vms_kiosk_tnc_required: true,
          vms_public_tnc_required: true,
          vms_pdpa_setting: "save_full",
          vms_host_invite_check_in_auto: true
        },
        tnc_metadata: {
          kiosk: {
            tnc_url: "https://www.google.com/",
            policy_url: "https://www.google.com/"
          },
          public: {
            tnc_url: "https://www.google.com/",
            policy_url: "https://www.google.com/"
          }
        }
      },

      vmsSettingForm: {
        public_url: null,
        public_hostname: null,
        public_protocol: "http://",
        recipient_list: [],
        dynamic_qr_validity: 15,
        generate_scheduled_report: true,
        visit_housekeeping_days: 90,
        visitor_housekeeping_days: 90,
        report_housekeeping_days: 90,
        report_generation_time: "15:30",
        report_generation_days: "0,1,2,3,4",
        report_generation_days_array: [0, 1, 2, 3, 4, 5],
        vms_overstayed_auto_checkout: false
      },

      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,

      form: null,
      formName: null,
      formData: null,
      selected: null,
      isSetting: false,

      formAccess: {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null
      },

      all_access_groups: {},
      all_access_levels: {},

      visitorTypeOptions: [],
      errorMessage: null,
      visit: [],
      count: 0,
      checkoutlist: null
    };
  },
  components: {
    QrcodeVue,
    Multiselect
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getPreferences: "vmsPreferences2Module/getPreferences",
      getSettings: "vmsSettingsModule/getSettings"
    })
  },
  watch: {
    getPreferences: {
      deep: true,
      handler: function (newVal) {
        this.vmsPreferenceForm = newVal;

        if (!this.firstLoad) {
          this.firstLoad = true;
        } else {
          this.hide();
        }
      }
    },

    getSettings: {
      deep: true,
      handler: function (newVal) {
        this.vmsSettingForm = newVal;
        this.hide();
        this.presentSetting();
      }
    },

    firstLoad: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          try {
            if (
              this.vmsPreferenceForm.additional_metadata
                .vms_registration_declaration_form &&
              this.vmsPreferenceForm.forms.formData
            ) {
              this.$nextTick(() => {
                this.showCustomForm(true);
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  },
  validations: {
    vmsPreferenceForm: {
      tolerance_time_in: { maxValue: maxValue(30), required },
      tolerance_time_out: { maxValue: maxValue(30), required }
    },
    vmsSettingForm: {
      dynamic_qr_validity: { between: between(10, 60), required }
    }
  },
  mounted: function () {
    this.getOverstayedVisit();
    this.getVisitorType();
    this.settingVMS();
    this.access();
  },
  methods: {
    async access() {
      try {
        let p1Urls = await this.fetchP1Members();
        this.acsUrls = p1Urls;

        for (let i = 0; i < this.acsUrls.length; i++) {
          this.$set(
            this.all_access_groups,
            this.acsUrls[i],
            await this.fetchAccessGroup(this.acsUrls[i])
          );

          this.$set(
            this.all_access_levels,
            this.acsUrls[i],
            await this.fetchAccessLevel(this.acsUrls[i])
          );
        }
        this.formAccess.selected_acs_url = this.acsUrls?.[0];
        this.formAccess.access_groups =
          this.all_access_groups[this.formAccess.selected_acs_url];
        this.options_vms_preferences_access_groups =
          this.formAccess.access_groups;

        this.formAccess.access_level =
          this.all_access_levels[this.formAccess.selected_acs_url];
        this.options_vms_preferences_access_level =
          this.formAccess.access_level;

        for (let key2 in this.options_vms_preferences_access_level) {
          if (
            this.options_vms_preferences_access_level[key2].access_level == "1"
          ) {
            this.vmsPreferenceForm.additional_metadata.vms_preferences_access_level =
              this.options_vms_preferences_access_level[key2];
          }
        }

        console.log(
          "all_access_groups",
          this.all_access_groups[this.formAccess.selected_acs_url]
        );
        console.log(
          "all_access_levels",
          this.all_access_levels[this.formAccess.selected_acs_url]
        );
      } catch (e) {
        console.log(e);
      }
    },
    async fetchP1Members() {
      let API_URL =
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl].result;
      }
      return [];
    },
    async fetchAccessGroup(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-groups/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let error = res.detail;
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }
      if (error != undefined) {
        error =
          "P1 is not connected. Please contact your system administrator. " +
          "If P1 is already connected, you should refresh the browser to be able to fill in the form again.";
        this.$bvModal.msgBoxOk(error, {
          centered: true
        });
        return false;
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },
    async fetchAccessLevel(acs_url) {
      var API_URL =
        this.getAPIServerURL +
        "/api/acsservice/info/access-levels/?acs_url=" +
        acs_url;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (
            result[key] !== undefined &&
            Array.isArray(result[key][acs_url])
          ) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (
        result[validUrl] != undefined &&
        Array.isArray(result[validUrl][acs_url])
      ) {
        return result[validUrl][acs_url];
      }
      return [];
    },

    settingVMS() {
      this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
      this.$store.dispatch("vmsSettingsModule/getSettings");
    },
    presentSetting() {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/settings/";
      const client = $this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL)
        .then((data) => {
          //data = { detail: "error" };
          if (data?.detail != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data?.detail,
              message_type: "danger"
            });
          }

          let validUrl = null;
          outer_loop: for (let key in data?.result) {
            validUrl = key;
            break outer_loop;
          }

          data.result[validUrl].report_generation_time = moment(
            data?.result?.[validUrl]?.report_generation_time,
            "hh:mm"
          ).format("hh:mm:ss");

          $this.oldVal = data?.result?.[validUrl];
          let urlObject;
          let hostName;
          let protocol;
          try {
            urlObject = new URL($this.oldVal.public_url);

            if (urlObject.pathname != undefined && urlObject.pathname != "/") {
              hostName = urlObject.hostname + urlObject.pathname;
              protocol = urlObject.protocol;
            } else {
              hostName = urlObject.hostname;
              protocol = urlObject.protocol;
            }
          } catch (e) {
            hostName = null;
            protocol = "http://";
          }
          let array = JSON.parse(
            "[" + this.oldVal.report_generation_days + "]"
          );
          let map = array.map((x) => "" + x + "");

          $this.oldVal = {
            ...$this.oldVal,
            public_hostname: hostName,
            public_protocol: protocol + "//",
            report_generation_days_array: map
          };

          console.log("oldVal", $this.oldVal);
        })
        .catch((err) => {});
    },

    getOverstayedVisit() {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/overstayed/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key in result[validUrl]) {
              this.visit.push(result[validUrl][key]);
            }
            console.log("this.visit", this.visit);
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    getVisitorType() {
      this.visitorTypeOptions = [];
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visitor-type/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          //debugger; // eslint-disable-line no-debugger
          this.errorMessage = data?.detail;

          if (this.errorMessage != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data?.detail,
              message_type: "danger"
            });
          }

          let validUrl = null;
          outer_loop: for (let key in data?.result) {
            if (Array.isArray(data?.result[key])) {
              validUrl = key;
              break outer_loop;
            }
          }

          if (Array.isArray(data?.result?.[validUrl])) {
            for (let key2 in data?.result?.[validUrl]) {
              data.result[validUrl][key2].name =
                data?.result?.[validUrl]?.[key2].name.toUpperCase();
              this.visitorTypeOptions.push(data?.result?.[validUrl]?.[key2]);
            }
            console.log("visitorTypeOptions", this.visitorTypeOptions);
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    disabled(type) {
      let result = null;
      switch (type) {
        case "physical card":
          result =
            this.vmsPreferenceForm.additional_metadata
              .vms_host_invite_check_in_auto;
          break;
        case "static qr":
          result =
            this.vmsPreferenceForm.additional_metadata
              .vms_credential_operator_dynamic_qr;
          break;
        case "dynamic qr":
          result =
            this.vmsPreferenceForm.additional_metadata
              .vms_credential_operator_dynamic_qr;
          break;
        case "face recognition":
          break;
        default:
      }
      return result;
    },

    // validation
    validatevmsPreferenceForm(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.vmsPreferenceForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationvmsPreferenceForm() {
      let $this = this;
      $this.$v.vmsPreferenceForm.$touch();
      return $this.$v.vmsPreferenceForm.$anyError;
    },
    validatevmsSettingForm(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.vmsSettingForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationvmsSettingForm() {
      let $this = this;
      $this.$v.vmsSettingForm.$touch();
      return $this.$v.vmsSettingForm.$anyError;
    },

    handleClose() {
      this.settingVMS();
    },
    handleCancel() {
      this.settingVMS();
    },
    handleOk(bvModalEvent) {
      let $this = this;
      let anyError = $this.onSubmitValidationvmsSettingForm();
      if (anyError) {
        // Prevent modal from closing
        bvModalEvent.preventDefault();
      }
    },

    compareObject() {
      let obj1 = JSON.stringify(this.oldVal);
      let obj2 = JSON.stringify(this.vmsSettingForm);
      if (obj1 != obj2) {
        this.isSetting = true;
      }
      console.log("this.isSetting", this.isSetting);
    },
    updatePublicSetting() {
      let $this = this;

      if (
        $this.vmsPreferenceForm.additional_metadata.public
          .vms_public_default_duration == "duration_time_range"
      ) {
        $this.vmsPreferenceForm.additional_metadata.public = {
          ...$this.vmsPreferenceForm.additional_metadata.public,
          vms_public_default_duration:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_default_duration,
          vms_public_visit_start_time:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_visit_start_time,
          vms_public_visit_end_time:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_visit_end_time,
          vms_public_duration_hours: 2,
          vms_public_visit_end_time_only: "18:00:00",
          vms_public_duration_days: 0
        };
      }

      if (
        $this.vmsPreferenceForm.additional_metadata.public
          .vms_public_default_duration == "duration_hours"
      ) {
        $this.vmsPreferenceForm.additional_metadata.public = {
          ...$this.vmsPreferenceForm.additional_metadata.public,
          vms_public_default_duration:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_default_duration,
          vms_public_visit_start_time: "10:00:00",
          vms_public_visit_end_time: "18:00:00",
          vms_public_duration_hours:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_duration_hours,
          vms_public_visit_end_time_only: "18:00:00",
          vms_public_duration_days: 0
        };
      }

      if (
        $this.vmsPreferenceForm.additional_metadata.public
          .vms_public_default_duration == "duration_end_time"
      ) {
        $this.vmsPreferenceForm.additional_metadata.public = {
          ...$this.vmsPreferenceForm.additional_metadata.public,
          vms_public_default_duration:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_default_duration,
          vms_public_visit_start_time: "10:00:00",
          vms_public_visit_end_time: "18:00:00",
          vms_public_duration_hours: 2,
          vms_public_visit_end_time_only:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_visit_end_time_only,
          vms_public_duration_days: 0
        };
      }

      if (
        $this.vmsPreferenceForm.additional_metadata.public
          .vms_public_default_duration == "duration_days"
      ) {
        $this.vmsPreferenceForm.additional_metadata.public = {
          ...$this.vmsPreferenceForm.additional_metadata.public,
          vms_public_default_duration:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_default_duration,
          vms_public_visit_start_time: "10:00:00",
          vms_public_visit_end_time: "18:00:00",
          vms_public_duration_hours: 2,
          vms_public_visit_end_time_only: "18:00:00",
          vms_public_duration_days:
            $this.vmsPreferenceForm?.additional_metadata?.public
              ?.vms_public_duration_days
        };
      }
    },
    handleUpdate(typeShow) {
      let $this = this;

      if ($this.$refs.input.computedInputAttrs.value != "") {
        $this.$refs.input.value.push(this.$refs.input.computedInputAttrs.value);
      }
      this.$refs.input.computedInputAttrs.value = "";

      this.compareObject();
      this.updatePublicSetting();

      this.$bvModal
        .msgBoxConfirm("Confirm update VMS Setting?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            try {
              this.show(typeShow);

              /* delete $this.vmsPreferenceForm.additional_metadata
                .vms_preferences_credential_dynamic_qr;
              delete $this.vmsPreferenceForm.additional_metadata
                .vms_preferences_credential_face_recognition;
              delete $this.vmsPreferenceForm.additional_metadata
                .vms_preferences_credential_physical_card;
              delete $this.vmsPreferenceForm.additional_metadata
                .vms_preferences_credential_static_qr; */

              if ($this.form != null) {
                $this.formData = $this.form?.actions?.getData("json", true);

                $this.vmsPreferenceForm = {
                  ...$this.vmsPreferenceForm,
                  forms: {
                    formData: $this.formData
                  }
                };
              } else {
                $this.vmsPreferenceForm = {
                  ...$this.vmsPreferenceForm
                };
              }

              let dynamic_qr_validity = parseInt(
                $this.vmsSettingForm?.dynamic_qr_validity
              );
              $this.vmsSettingForm.dynamic_qr_validity = dynamic_qr_validity;

              console.log("this.vmsPreferenceForm", this.vmsPreferenceForm);
              console.log("this.vmsSettingForm", this.vmsSettingForm);
              console.log("this.isSetting", this.isSetting);

              if ($this.vmsPreferenceForm.id == undefined) {
                console.log("createPreferences");
                $this.$store.dispatch(
                  "vmsPreferences2Module/createPreferences",
                  $this.vmsPreferenceForm
                );
              } else {
                console.log("updatePreferences");
                $this.$store.dispatch(
                  "vmsPreferences2Module/updatePreferences",
                  $this.vmsPreferenceForm
                );
              }

              if (this.isSetting) {
                this.isSetting = false;
                console.log("updateSettings");
                $this.$store.dispatch(
                  "vmsSettingsModule/updateSettings",
                  $this.vmsSettingForm
                );
              }
              this.$v.$reset();
            } catch (e) {
              this.hide();
              console.log("e");
              console.log(e);
            }
          } else {
            $this.$refs.input.value = [];
            $this.$refs.input.value = this.oldVal.recipient_list;
            this.hide();
          }
        })
        .catch((err) => {
          // An error occurred
          this.hide();
        });
    },

    showImage: function (img) {
      return `data:image/jpeg;base64,${img}`;
    },

    onChange: function (data) {
      data
        ? ((this.vmsPreferenceForm.additional_metadata.vms_credential_operator_physical_card = false),
          (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_physical_card = false),
          (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_fingerprint = false),
          (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_fingerprint = false))
        : null;
    },
    onChangeCredentialIssuance: function (type, data) {
      switch (type) {
        case "physical_card_operator":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_physical_card = false)
            : null;
          break;
        case "physical_card_kiosk":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_lift_access_physical_card = false)
            : null;
          break;
        case "static_qr_operator":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_static_qr = false)
            : (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_dynamic_qr = false);
          break;
        case "static_qr_kiosk":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_lift_access_static_qr = false)
            : (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_dynamic_qr = false);
          break;
        case "dynamic_qr_operator":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_dynamic_qr = false)
            : ((this.vmsPreferenceForm.additional_metadata.vms_credential_operator_static_qr = false),
              this.$bvModal.show("dynamic-qr-form-modal"));
          break;
        case "dynamic_qr_kiosk":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_lift_access_dynamic_qr = false)
            : ((this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_static_qr = false),
              this.$bvModal.show("dynamic-qr-form-modal"));
          break;
        case "face_recognition_operator":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_face_recognition = false)
            : null;
          break;
        case "face_recognition_kiosk":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_lift_access_face_recognition = false)
            : null;
          break;
        case "license_plate_recognition_operator":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_license_plate_recognition = false)
            : null;
          break;
        case "license_plate_recognition_kiosk":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_lift_access_license_plate_recognition = false)
            : null;
          break;
        case "fingerprint_operator":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_operator_lift_access_fingerprint = false)
            : null;
          break;
        case "fingerprint_kiosk":
          !data
            ? (this.vmsPreferenceForm.additional_metadata.vms_credential_kiosk_lift_access_fingerprint = false)
            : null;
          break;
      }
    },

    handleCustomForm: function (data) {
      if (data) {
        this.vmsPreferenceForm.forms = {
          ...this.vmsPreferenceForm.forms,
          formData: this.formData
        };

        this.showCustomForm(true);
      } else {
        this.vmsPreferenceForm.forms = {};
      }
    },
    showCustomForm: function (data) {
      if (data) {
        let that = this;

        let is_jQuery = $ === jQuery;

        if (is_jQuery) {
          let options = {
            disabledActionButtons: ["data", "save", "clear"]
          };

          let fbEditor = document.getElementById("form-editor");

          $(fbEditor)
            .formBuilder(options)
            .promise.then((formBuilder) => {
              formBuilder.actions.setData(
                that.vmsPreferenceForm?.forms?.formData
              );

              this.form = formBuilder;
            });

          //this.hide();
        }
      }
    },
    uploadPhoto: function (type) {
      let $this = this;
      if (type === "kiosk") {
        $this.vms_kiosk_company_banner = null;
        if (document.getElementById("vms_kiosk_company_banner").value !== "") {
          document.getElementById("vms_kiosk_company_banner").value = "";
        }
        document.getElementById("vms_kiosk_company_banner").click();
      } else {
        $this.vms_public_url_company_banner = null;
        if (
          document.getElementById("vms_public_url_company_banner").value !== ""
        ) {
          document.getElementById("vms_public_url_company_banner").value = "";
        }
        document.getElementById("vms_public_url_company_banner").click();
      }
    },
    onFileChange: function (item, e, type) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(item, files[0], type);
    },
    createImage: function (item, file, type) {
      let $this = this;
      let reader = new FileReader();

      reader.onload = async (e) => {
        let base64Image = e.target.result;
        item = base64Image.substring(base64Image.indexOf(",") + 1);
        if (type === "kiosk") {
          $this.vmsPreferenceForm.additional_metadata.vms_kiosk_branding_image =
            item;
        } else {
          $this.vmsPreferenceForm.additional_metadata.public.vms_public_branding_image =
            item;
        }
      };
      reader.readAsDataURL(file);
    },
    downloadPdf() {
      var doc = new jsPDF("p", "pt", "A4");
      let margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522
      };

      if (this.$refs.previewPdfHtml != undefined) {
        doc.html(this.$refs.previewPdfHtml, {
          callback: function (doc) {
            doc.setFontSize(30);
            doc.setLineWidth(2);
            doc.rect(20, 20, 555, 800);
            //doc.save("output.pdf");
            doc.save("QR.pdf");
          },
          // x: 10,
          // y: 10
          x: 80,
          y: 130
        });
      } else {
        doc.html(this.$refs.previewPdfHtml, {
          callback: function (doc) {
            doc.setFontSize(30);
            doc.setLineWidth(2);
            doc.rect(20, 20, 555, 800);
            //doc.save("output.pdf");
            doc.save("QR.pdf");
          },
          // x: 10,
          // y: 10
          x: 70,
          y: 130
        });
      }
    },
    printPdf() {
      var doc = new jsPDF("p", "pt", "A4");
      let margins = {
        top: 80,
        bottom: 60,
        left: 40,
        width: 522
      };

      if (this.$refs.previewPdfHtml != undefined) {
        doc.html(this.$refs.previewPdfHtml, {
          callback: function (doc) {
            doc.setFontSize(30);
            doc.setLineWidth(2);
            doc.rect(20, 20, 555, 800);
            doc.autoPrint();
            doc.output("dataurlnewwindow");
          },
          x: 70,
          y: 130
        });
      } else {
        doc.html(this.$refs.previewPdfHtml, {
          callback: function (doc) {
            doc.setFontSize(30);
            doc.setLineWidth(2);
            doc.rect(20, 20, 555, 800);
            doc.autoPrint();
            doc.output("dataurlnewwindow");
          },
          // x: 10,
          // y: 10
          x: 70,
          y: 130
        });
      }
    },

    handleQRGeneratorPreview() {
      this.$refs["previewQRGenerator"].show();
    },
    tagValidator(receipient_list) {
      // Individual tag validator function
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(receipient_list);
    },

    show(value) {
      if (value == "show1") {
        this.show1 = true;
      } else if (value == "show2") {
        this.show2 = true;
      } else if (value == "show3") {
        this.show3 = true;
      } else if (value == "show4") {
        this.show4 = true;
      } else if (value == "show5") {
        this.show5 = true;
      } else if (value == "show6") {
        this.show6 = true;
      } else if (value == "show7") {
        this.show7 = true;
      } else if (value == "show8") {
        this.show8 = true;
      }
    },
    hide() {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      this.show6 = false;
      this.show7 = false;
      this.show8 = false;
    },

    confirmCheckOut() {
      let visitLength = this.visit.length;
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to check out all " +
            this.visit.length +
            " overstayed visitor?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            for (let key in this.visit) {
              this.checkoutlist = await this.checkOut(this.visit[key]);
            }
            if (this.checkoutlist == visitLength) {
              this.$bvModal.msgBoxOk(
                "Total " +
                  this.visit.length +
                  " visitors have successful checked-out.",
                {
                  centered: true
                }
              );
              return false;
            }
          } else {
            //
          }
        })
        .catch((err) => {
          // An error occurred
          this.hide();
        });
    },
    async checkOut(visit) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/check-out/?visit_id=" +
        visit.id;

      const client = this.$root.getAjaxFetchClient();
      let res = await client.postRequest(API_URL);
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] == null) {
        this.count++;
        return this.count;
      }
      return [];
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.invalid-feedback_ {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d53e4c;
}
.invalid-feedback__ {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d53e4c;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: var(--colour-text-on-bg) !important;
}

.kiosk-company-banner {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: var(--colour-bg);
  border-radius: $border-radius-card;
  border: 3px solid $white;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.public-url-company-banner {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: var(--colour-bg);
  border-radius: $border-radius-card;
  border: 3px solid $white;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.qrGeneratorFont1 {
  font-size: xxx-large;
  font-weight: 700;
  //letter-spacing: 5rem;
  word-spacing: 1rem;
  margin-bottom: 1rem;
}

.qrGeneratorFont2 {
  font-size: x-large;
  word-spacing: 0.4rem;
}

.qr-font {
  font-size: x-large;
  word-wrap: break-word;
}

.modal-dialog-scrollable .modal-body {
  overflow: auto;
}
div.visit-align {
  width: auto;
}

.custom-select.duration-select {
  width: 180px;
}
</style>
